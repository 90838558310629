.contentWrapper {
    display: flex;
    gap: 10px;
}

.statusIconWrapper {
    display: flex;
    align-items: center;
}

.secondaryButton {
    color: white !important;
    text-transform: none !important;
    background: #a352c2 !important;
    border-radius: 25px !important;
    padding-block: 1px !important;
}

.secondaryButton:hover,
.secondaryButton:focus
{
    background: #a352c2 !important;
}

.dataTooltipContent {
    width: 100%;
    min-width: 250px;
}

.dataTooltipHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px 5px 5px;
    margin-bottom: 10px;
}

.statusChip{
    color: #fff;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.statusChipInvalid {
    background-color: #F30000;
}

.statusChipValid {
    background-color: #87b787;
}

.dataElement {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    background-color: #454b5b;
    margin-bottom: 10px;
    border-radius: 4px;
}

.dataElementKey {
    font-weight: 600;
    width: 30%;
}

.dataElementValue {
    width: 70%;
    text-align: start;
    margin-left: 10px;
}

.emptyPlaceholder {
    padding: 10px;
}