.share_contact_info_modal textarea {
    height: 80px;
}
.share_contact_info_modal textarea, .share_contact_info_modal input {
    border: 1px solid #33333370 !important;
    border-radius: 5px !important;
    resize: none;
    padding: 10px !important;
    box-sizing: border-box;
    color: #333;
    font-size: 15px;
    font-weight: normal;
    box-shadow: none !important;
    display: block;
}

.share_contact_info_modal textarea:focus, .share_contact_info_modal input:focus {
    outline: 0;
}

.globalModalWrapper.share_contact_info_modal {
    min-width: unset !important;
    width: 800px;
    max-width: unset !important;
}

.share_contact_info_modal .text-error .form-error {
    font-size: 14px;
    font-style: italic;
}

.share_contact_info_modal label {
    color: #333;
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 6px;
    display: block;
}

.globalModalWrapper .massage_send_close_button .send_massage span {
    width: 26px;
    height: 26px;
    border: 1px solid #fff;
    display: grid;
    border-radius: 50%;
    line-height: 27px;
    text-align: center;
    margin: 0;
    place-items: center;
}

.share_contact_info_modal ::placeholder {
    color: #333;
    opacity: 1;
}

.share_contact_info_modal ::-ms-input-placeholder {
    color: #333;
}

.globalModalWrapper .massage_send_close_button p.global-modal-buttontext {
    margin: 3px;
    padding: 0 0 0 5px;
    color: #fff!important;
}

.globalModalWrapper .massage_send_close_button .close_btn, .globalModalWrapper .massage_send_close_button .send_massage {
    align-items: center;
    gap: 6px;
}