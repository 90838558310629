.leadstatus {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.leadOwnerBoxWidth {
  width: 40%;
}

.leadTypeBoxWidth {
  width: 40%;
}
.basicinputbackground .MuiSelect-selectMenu .selectedTitle {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1366px) {
  .leadstatus {
    display: block;
  }
  .leadTypeBoxWidth {
    width: 100%;
  }
  .leadOwnerBoxWidth {
    width: 100%;
    margin-bottom: 20px;
  }
  .leadOwnerTextWidth {
    line-height: 1.2;
    width: 100%;
  }
}

/* @media only screen and (min-width: 1366px) and (max-width: 1700px) {
  .leadOwnerTextWidth {
    width: 69px;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1700px) and (max-width: 1900px) {
  .leadOwnerTextWidth {
    width: 116px;
    display: block;
    overflow: hidden;
  }
  .basicinputbackground .MuiSelect-selectMenu .selectedTitle {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 1900px) and (max-width: 2200px) {
  .leadOwnerTextWidth {
    width: 130px;

  }
} */
