.fantasticScrollbar {
    overflow-y: auto;
}

.fantasticScrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.fantasticScrollbar::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}
.fantasticScrollbar::-webkit-scrollbar {
    scrollbar-width: thin;
}
.fantasticScrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}
.fantasticScrollbar::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}