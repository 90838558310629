.button{
    background-color: #a352c2;
    color: #fff;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 10px;
    cursor: pointer;
}

.button:hover{
    background-color: #9c4fbb;
}

.content {
    position: absolute;
}

.statusChip{
    color: #fff;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.statusChipInvalid {
    background-color: #F30000;
}

.statusChipValid {
    background-color: #87b787;
}