.conversation_export_loader .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important;
    position: relative;
}

.conversation_export_loader .makeStyles-root-7 {
    height: 20px;
}

.conversation_export_loader_wrapper {
    height: 30px;
    width: 30px;
    border: 1px solid #133159;
    border-radius: 50%;
    display: grid;
    place-items: center;
}
