.modalWrapper {
    display: grid;
    place-content: center;
}

.modalInner {

}

.modalInner:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.header{
    width: 100%;
    min-height: 40px;
    padding: 20px 14px 14px 22px;
    display: flex;
    justify-content: space-between;
    background-color: #3C7ef3;
}

.headerTitle {
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 10px;
}

.close{
    cursor: pointer;
    color: #ffffff;
}

.body{
    width: 512px;
    height: 600px;
    overflow-y: auto;
    padding: 20px 25px 20px 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.body:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.body::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.body::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}
.body::-webkit-scrollbar {
    scrollbar-width: thin;
}
.body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}
.body::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}

.logItem {
    padding: 12px 14px;
    background-color: #efeeee;
    border-radius: 4px;
}

.logText {
    font-size: 12px !important;
    color: #3b3b4d;
    height: unset !important;
}

.logTextTime {
    font-size: 11px !important;
    color: #3b3b4d;
    height: unset !important;
}

.noLogText {
    text-align: center;
    font-size: 11px !important;
    color: #3b3b4d;
    height: unset !important;
}

