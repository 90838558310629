.buttonStyle {
  background-color: #00ff91 !important;
  height: 20px !important;
  width: 85px !important;
  display: flex !important;
  font-size: 12px !important;
  padding: 0 15px !important;
  color: #0e0f3f !important;
  font-weight: 500 !important;
  line-height: 0px !important;
  border-radius: 40px !important;
  margin-left: auto;
}
.buttonStyle:hover {
  background-color: #00ff91 !important;
}
.middleLine {
  width: 96% !important;
  height: 2px !important;
  background-color: #ced4db;
  margin-top: 10px;
  margin-left: 5px;
}
.validButton {
  width: 66px !important;
}
.checkButton {
  width: 92px !important;
}

/* .address_text{
    width: 80% !important;
    height: 2px !important;
    background-color: #ced4db;
    position: absolute;
    left: 72px;
    top: 10px;
} */
.edit_icon {
  width: 30px !important;
}

.hoverOnCustomTitle :hover {
  display: block;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  visibility: visible;
}
@media only screen and (max-width: 1650px) {
  .buttonStyle {
    background-color: #00ff91;
    height: 19px;
    width: 65px;
    display: flex;
    font-size: 10px;
    border-radius: 30px;
    padding-left: 9px !important;
  }
  .buttonStyle:hover {
    background-color: #00ff91;
  }
  .middleLine {
    width: 70%;
  }
  .address_text1 {
    width: 46%;
  }
  .edit_icon {
    /* width: 0.8em !important; */
    height: 20px !important;
  }
  .icons_formate {
    left: 193px;
    top: -4px;
  }
}
