:root {
  --light-blue-10: rgba(60, 126, 243, 0.1);
  --yellow: #f2c94c;
  --yellow-10: rgba(242, 200, 76, 0.1);
  --shade: #f0efff;
  --green: #6fcf97;
  --orange: #f2994a;
  --green-10: rgba(39, 174, 95, 0.1);
  --red-10: rgba(255, 38, 74, 0.1);
  --dark-blue-05: rgba(19, 49, 89, 0.05);
  --dark-blue-10: rgba(19, 49, 89, 0.1);
  --dark-blue-65: rgba(19, 49, 89, 0.65);
}

.inbox-overflow--hidden {
  overflow: hidden;
}

/* Global CSS */

.InboxModule-container-parent-class .b-radius-10 {
  border-radius: 10px !important;
}

.InboxModule-container-parent-class .mb-25 {
  margin-bottom: 25px !important;
}

.InboxModule-container-parent-class .ml-25 {
  margin-left: 25px !important;
}

.InboxModule-container-parent-class .mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.InboxModule-container-parent-class .p-t-30 {
  padding-top: 30px;
}

.InboxModule-container-parent-class .mb-20 {
  margin-bottom: 20px;
}

.InboxModule-container-parent-class .p-relative {
  position: relative !important;
}

/* .m-height-100 {
  min-height: 100% !important;
} */

#cutomScrollbar1::-webkit-scrollbar-track,
#cutomScrollbar2::-webkit-scrollbar-track,
#cutomScrollbar3::-webkit-scrollbar-track,
#cutomScrollbar4::-webkit-scrollbar-track,
#cutomScrollbar5::-webkit-scrollbar-track,
#cutomScrollbar6::-webkit-scrollbar-track,
#tabScrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 10px rgba(60, 126, 243, 0.1);
  background-color: rgba(60, 126, 243, 0.1);
  border-radius: 10px;
}

#cutomScrollbar1,
#cutomScrollbar2,
#cutomScrollbar3,
#cutomScrollbar4,
#cutomScrollbar5,
#cutomScrollbar6,
#tabScrollbar {
  /* Thumb */
  scrollbar-face-color: #133159;
  scrollbar-shadow-color: #133159;
  scrollbar-highlight-color: #133159;
  scrollbar-3dlight-color: #133159;
  scrollbar-darkshadow-color: #133159;
  /* Track */
  scrollbar-track-color: #ECF3FE;
  /* Buttons */
  scrollbar-arrow-color: #133159;
}

#cutomScrollbar1::-webkit-scrollbar-thumb,
#cutomScrollbar2::-webkit-scrollbar-thumb,
#cutomScrollbar3::-webkit-scrollbar-thumb,
#cutomScrollbar4::-webkit-scrollbar-thumb,
#cutomScrollbar5::-webkit-scrollbar-thumb,
#cutomScrollbar6::-webkit-scrollbar-thumb,
#tabScrollbar::-webkit-scrollbar-thumb {
  background-color: #133159;
  width: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

#cutomScrollbar1::-webkit-scrollbar,
#cutomScrollbar2::-webkit-scrollbar,
#cutomScrollbar3::-webkit-scrollbar,
#cutomScrollbar4::-webkit-scrollbar,
#cutomScrollbar5::-webkit-scrollbar,
#cutomScrollbar6::-webkit-scrollbar,
#tabScrollbar::-webkit-scrollbar {
  background-color: #ECF3FE;
  width: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

#cutomScrollbar1,
#cutomScrollbar2,
#cutomScrollbar3,
#cutomScrollbar4,
#cutomScrollbar5,
#cutomScrollbar6,
#tabScrollbar {
  scrollbar-color: #133159 #ECF3FE;
  scrollbar-width: thin;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

#cutomScrollbar1::-webkit-scrollbar,
#cutomScrollbar2::-webkit-scrollbar,
#cutomScrollbar3::-webkit-scrollbar,
#cutomScrollbar4::-webkit-scrollbar,
#cutomScrollbar5::-webkit-scrollbar,
#cutomScrollbar6::-webkit-scrollbar,
#tabScrollbar::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(60, 126, 243, 0.1);
  border-radius: 10px;
}

#cutomScrollbar1::-webkit-scrollbar-thumb,
#cutomScrollbar2::-webkit-scrollbar-thumb,
#cutomScrollbar3::-webkit-scrollbar-thumb,
#cutomScrollbar4::-webkit-scrollbar-thumb,
#cutomScrollbar5::-webkit-scrollbar-thumb,
#cutomScrollbar6::-webkit-scrollbar-thumb,
#tabScrollbar::-webkit-scrollbar-thumb {
  background-color: #133159;
  border-radius: 10px;
}

.InboxModule-container-parent-class .pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 38, 74, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 38, 74, 0.0);
  }
}

.InboxModule-container-parent-class .inbox_toggle_btn.switch-icon {
  top: 10px;
  width: 22px;
}

/* .switch-icon svg {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
  margin-right: 3px;
  pointer-events: all;
}

.switch-icon svg.active {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  margin-right: 5px;
} */

/**************************************
Inbox Empty Page CSS  
**************************************/

.inbox-main-section {
  /* height: calc(100vh - 188px); */
  /* height: calc(100vh - 232px); */
  height: calc(100vh - 200px);
}

.InboxModule-container-parent-class .message-info {
  min-height: auto;
  height: calc(100vh - 140px);
}

.InboxModule-container-parent-class .message-info .filtering-buttons {
  width: 100%;
  height: 30px;
}

.InboxModule-container-parent-class .message-info .filtering-buttons .customSelectLi {
  flex: 0.8;
}

.InboxModule-container-parent-class .message-info .filtering-buttons .filtering-button-trigger {
  flex: 0.1;
}

/* Search Box CSS [Left Section] */

.InboxModule-container-parent-class .search-box {
  padding: 20px 20px 18px 20px;
}

.InboxModule-container-parent-class .search-box input[type="search"] {
  background: #F6F7FB;
  -webkit-box-shadow: rgb(0 0 0 / 15%) 0px 2px 16.6px, inset 4px 4px 2px rgb(8 20 36 / 10%);
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 16.6px, inset 4px 4px 2px rgb(8 20 36 / 10%);
  border-radius: 10px;
  Height: 35.29px;
  font-family: var(--poppins);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #546376;
  /* opacity: 0.6; */
  padding: 0 12px;
  border: none;
  margin-bottom: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.InboxModule-container-parent-class .search-box input[type="search"]~.material-icons {
  right: 30px;
  top: 30px;
  color: #133159;
  font-size: 17px;
}

.InboxModule-container-parent-class .search-box input[type=search]:focus:not(.browser-default) {
  /* border: 1px solid transparent; */
  background-color: #F6F7FB;
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 16.6px, inset 4px 4px 2px rgb(8 20 36 / 10%);
}

/* Filtering Button CSS [Left Section] */

.InboxModule-container-parent-class .filtering-buttons {
  padding: 0 20px;
}

.InboxModule-container-parent-class .filtering-buttons a {
  width: 40px;
  height: 40px;
  background: #F6F7FB;
  border-radius: 6px;
  text-align: center;
  /* margin-left: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

/* All Message Tabs CSS [Left Section] */

.InboxModule-container-parent-class .all-message-tabs {
  clear: both;
  padding-top: 11px;
  padding-bottom: 0;
  padding-left: 18px;
  padding-right: 10px;
  margin-top: 10px;
}

.InboxModule-container-parent-class .border-bottom {
  position: absolute;
  height: 2px;
  will-change: left, right;
  background-color: #E5E8EF;
  z-index: 1;
  bottom: 20px;
  width: 100%;
  left: 0;
}

.InboxModule-container-parent-class .message-links-tab {
  height: 60px;
  overflow-x: hidden;
}

.InboxModule-container-parent-class .message-links-tab .tab {
  line-height: 40px;
  height: 40px;
}

.InboxModule-container-parent-class .message-links-tab:after {
  content: "";
  width: 97%;
  height: 2px;
  background-color: #E5E8EF;
  display: block;
  bottom: 20px;
  position: absolute;
}

.InboxModule-container-parent-class .message-links-tab .tab a {
  color: #133159;
  font-size: 14px;
  padding: 0;
  line-height: 40px;
  font-weight: 400;
  text-transform: capitalize;
}

.InboxModule-container-parent-class .message-links-tab .tab a:focus,
.message-links-tab .tab a:focus.active {
  background-color: transparent;
}

.InboxModule-container-parent-class .message-links-tab .tab a:hover,
.message-links-tab .tab a.active {
  color: #3C7EF3;
}

.InboxModule-container-parent-class .message-links-tab .indicator {
  background-color: #3C7EF3;
  z-index: 1;
  bottom: 20px;
}

/* Message Discussion CSS [Left Section] */

.InboxModule-container-parent-class .all-messages {
  height: calc(100vh - 327px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 12px;
}

.InboxModule-container-parent-class .message-discussion {
  padding: 10px;
  margin: 0 0 10px 0;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  cursor: pointer;
}

.InboxModule-container-parent-class .bg-active {
  background: rgba(60, 126, 243, 0.1);
}

.InboxModule-container-parent-class .message-discussion .user-short-name {
  padding: 0;
  margin-right: 15px;
}

.InboxModule-container-parent-class .message-discussion .user-area {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #F6F7FB;
  color: #133159;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  padding: 15px;
  display: grid;
  place-items: center;
  position: relative;
}

body .message-discussion .user-area i {
  /*position: absolute;*/
  /*font-size: 10px;*/

  top: unset;
  left: unset;
  top: 0 !important;
  left: 0;
  padding: 0;
  width: auto;
  height: auto;
  font-size: 18px;
}

.InboxModule-container-parent-class .message-discussion .user-area i.dots {
  top: 5px;
  left: 0px;
  color: #68EBCA;
}

/*.message-discussion .user-area i.bg-icons {*/
/*  bottom: -5px;*/
/*  right: 0;*/
/*  background-color: #fff;*/
/*  width: 20px;*/
/*  height: 20px;*/
/*  display: inline-block;*/
/*  text-align: center;*/
/*  border-radius: 10px;*/
/*  line-height: 20px;*/
/*}*/

.InboxModule-container-parent-class .message-discussion .user-message {
  padding-right: 0;
  padding-left: 1px;
  position: relative;
}

.InboxModule-container-parent-class .message-discussion .user-message .thread-message-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  max-height: 22px;
}

.InboxModule-container-parent-class .message-discussion .user-message .thread-message-time {
  /* float: right; */
  font-size: 12px;
  color: #546376;
  line-height: 22px;
  /* margin-right: 10px; */
  flex-shrink: 0;
}

.InboxModule-container-parent-class .inbox-vertical-separator {
  color: #546376;
  margin: 0 5px;
}

.InboxModule-container-parent-class .message-discussion .user-message span.full-name {
  line-height: 24px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  color: #133159;
}

.InboxModule-container-parent-class .message-discussion .user-message span.new {
  display: inline-table;
  font-size: 12px;
  background: #FF264A;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  padding: 0px 8px;
  margin-left: 10px;
  line-height: 15px;
  text-transform: lowercase;
}

.InboxModule-container-parent-class .message-discussion .user-message span.hours {
  /* font-size: 12px; */
  font-size: 10px;
  color: #546376;
}

.InboxModule-container-parent-class .message-discussion .user-message .hours-wrapper {
  position: absolute;
  /* top: -10px; */
  top: -13px;
  right: 0px;
}

.InboxModule-container-parent-class .message-discussion .user-message p {
  font-size: 12px;
  color: #546376;
  line-height: 22px;
  margin: 0;
}

.InboxModule-container-parent-class .message-discussion .user-area i.mail {
  color: #4277FD;
}

.InboxModule-container-parent-class .message-discussion .user-area i.voicemail {
  color: #44ac3f;
}

.InboxModule-container-parent-class .message-discussion .user-area i.videocam {
  color: #F2994A;
}

.InboxModule-container-parent-class .message-discussion .user-area i.voice {
  color: #4F4F4F;
}

.InboxModule-container-parent-class .message-discussion .user-area i.sms {
  color: #56CCF2;
}

.InboxModule-container-parent-class .message-discussion .user-area i.phone {
  color: #27AE60;
}

.InboxModule-container-parent-class .message-discussion .user-area i.record_voice_over {
  color: #44ac3f;
}

/* Top Action Bar CSS [Right Section] */

.InboxModule-container-parent-class .message-info-details {
  margin-left: 0px;
  /*min-height: 913px;*/
  height: calc(100vh - 140px);
}

.InboxModule-container-parent-class .top-action-bar {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #E5E8EF;
  padding-right: 18px;
}

.InboxModule-container-parent-class .top-action-bar ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.InboxModule-container-parent-class .top-action-bar ul li {
  margin-left: 14px;
}

.InboxModule-container-parent-class .top-action-bar ul li a {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #133159;
  line-height: 30px;
  transition: 0.3s;
}

.InboxModule-container-parent-class .top-action-bar ul li a:hover {
  background-color: #E5E8EF;
  color: #fff;
}

/* No Data Found CSS [Right Section] */

.InboxModule-container-parent-class .no-data-found {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.InboxModule-container-parent-class .no-data-found img {
  margin-top: 98px;
  margin-bottom: 105px;
}

.InboxModule-container-parent-class .no-data-found h3 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}

.InboxModule-container-parent-class .no-data-found p {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #546376;
  margin: 0;
}

/* Send Message Button CSS [Right Section] */

.InboxModule-container-parent-class .send-message-btn {
  padding-right: 20px;
  padding-bottom: 30px;
  /* position: absolute;
  bottom: 0;
  right: 0; */
}

.InboxModule-container-parent-class .update__empty_inbox .send-message-btn {
  padding: 0 0 10px;
}

.InboxModule-container-parent-class .send-message-btn a {
  background: #3C7EF3;
  border-radius: 10px;
  padding: 12px 15px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.InboxModule-container-parent-class .send-message-btn a i {
  width: 26px;
  height: 26px;
  background: #fff;
  color: #3C7EF3;
  border-radius: 13px;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  line-height: 26px;
  margin-right: 10px;
}

/**************************************
Inbox Empty Page CSS  
**************************************/

.InboxModule-container-parent-class .user-message-info {
  min-height: auto;
  height: calc(100vh - 140px);
  overflow-x: hidden;
  overflow-y: auto;
}

.InboxModule-container-parent-class .no-details-found {
  text-align: center;
  margin-top: 10px;
  padding: 0 17px 0 11px;
}

.InboxModule-container-parent-class .no-details-found h3 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
}

.InboxModule-container-parent-class .no-details-found p {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #546376;
  margin: 0;
}

/**************************************
Inbox Expand SMS CSS  
**************************************/

.InboxModule-container-parent-class .dat-chip {
  left: 50%;
  width: 142px;
  height: 22px;
  background: #E5E8EF;
  border-radius: 100px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #546376;
  text-align: center;
  margin: -20px auto 0;
}

.InboxModule-container-parent-class .timeDiv {
  margin: 20px 0;
}

.InboxModule-container-parent-class .timeDiv hr {
  color: #f2f2f25c;
}

.InboxModule-container-parent-class .user-name-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.InboxModule-container-parent-class .user-name-area .user-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 9px;
}

.InboxModule-container-parent-class .user-name-area p {
  margin: 0 0 8px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #333333;
}

.InboxModule-container-parent-class .user-name-area span {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #546376;
}

.InboxModule-container-parent-class .audio-setup ul li span {
  font-size: 12px;
  color: #546376;
}

.InboxModule-container-parent-class .audio-setup ul li svg {
  vertical-align: middle;
}

/* Chatbox CSS [Main Section]*/

.InboxModule-container-parent-class .chat__bottom_wrapper {
  height: 100%;
  display: block !important;
}

.InboxModule-container-parent-class .chatbox-area {
  /* border: 2px solid #71D8AE; */
  overflow-y: auto;
  overflow-x: hidden;
  /* height: calc(100% - 480px); */
  height: calc(100% - 380px);
  min-height: auto;
  padding-bottom: 30px;
  padding-top: 25px;
}

.InboxModule-container-parent-class .chatbox-empty {
  /* border: 2px solid #71D8AE; */
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 60px);
  min-height: auto;
  padding-bottom: 30px;
  padding-top: 25px;
}

.InboxModule-container-parent-class .chatbox-area.inbox-text-active {
  /* height: calc(100% - 390px); */
  height: calc(100% - 341px);
}

/* .chatbox-area.inbox-email-active {
  height: calc(100% - 430px);
}  */

.InboxModule-container-parent-class .chatbox-area.inbox-video-email-active,
.chatbox-area.inbox-email-active {
  /* height: calc(100% - 430px); */
  height: calc(100% - 381px);
}

.InboxModule-container-parent-class .chatbox-area.inbox-voice-active {
  height: calc(100% - 363px);
}

.InboxModule-container-parent-class .chatbox-area.inbox-directmail-active {
  height: calc(100% - 297px);
}

.InboxModule-container-parent-class .chatbox-area.inbox-chat-active {
  height: calc(100% - 294px);
}

.InboxModule-container-parent-class .chatbox-area.inbox-note-active {
  height: calc(100% - 294px);
}

/* Reply Message Component CSS [Chatbox Area] */

.InboxModule-container-parent-class .chatbox-area .reply-messages {
  margin-bottom: 15px;
}

.InboxModule-container-parent-class .chatbox-area .reply-messages .user-short-name {
  padding: 0 20px 10px 20px;
}

.InboxModule-container-parent-class .chatbox-area .reply-messages .user-short-name span {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #133159;
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 50px;
}

.InboxModule-container-parent-class .chatbox-area .reply-messages .chat-text {
  background: #F6F7FB;
  border-radius: 10px 10px 10px 0px;
  padding: 12px 15px;
  /* word-break: break-all; */
}

.InboxModule-container-parent-class .chatbox-area .reply-messages .chat-text p {
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #546376;
}

.InboxModule-container-parent-class .chatbox-area .reply-messages .chat-text p span {
  padding-right: 7px;
  display: inline-block;
}

.InboxModule-container-parent-class .chatbox-area .reply-messages .dropdown-icon .dropdown-trigger {
  color: #E5E8EF;
  display: inline-block;
  margin-left: -10px;
}

.InboxModule-container-parent-class .chatbox-area .reply-messages .dropdown-icon {
  position: absolute;
  right: -15px;
  top: 45%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.InboxModule-container-parent-class .chatbox-area .text-messages .dropdown-icon {
  position: absolute;
  left: -25px;
  top: 45%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.InboxModule-container-parent-class .chatbox-area .reply-messages .activation-time {
  margin-right: 0px;
}

.InboxModule-container-parent-class .chatbox-area .reply-messages .activation-time span {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(19, 49, 89, 0.35);
}

.InboxModule-container-parent-class .reply-messages .activation-time span i {
  font-size: 13.5px;
  color: #F2994A;
  vertical-align: middle;
  margin-right: 7px;
}

.InboxModule-container-parent-class .reply-messages .activation-time span svg {
  vertical-align: middle;
  margin-left: 7px;
}

/* Text Messsage Component CSS [Chatbox Area] */

.InboxModule-container-parent-class .chatbox-area .text-messages {
  margin-bottom: 15px;
  /* padding: 0 13% 0 0; */
}

.InboxModule-container-parent-class .chatbox-area .text-messages {
  padding-right: 40px;
  box-sizing: border-box;
  margin-left: 100px;
  width: calc(100% - 140px) !important;
}

.InboxModule-container-parent-class .chatbox-area .text-messages .dropdown-icon .dropdown-trigger {
  color: #133159;
  display: inline-block;
}

.InboxModule-container-parent-class .chatbox-area .text-messages .chat-text {
  padding: 12px 15px;
  background: rgba(60, 126, 243, 0.1);
  border-radius: 10px 10px 0px 10px;
  /* word-break: break-all; */
}

.InboxModule-container-parent-class .chatbox-area .text-messages .chat-text p {
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #133159;
}

.InboxModule-container-parent-class .chatbox-area .text-messages .activation-time {
  margin-left: 25px;
}

.InboxModule-container-parent-class .chatbox-area .text-messages .activation-time span {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(19, 49, 89, 0.65);
}

.InboxModule-container-parent-class .chatbox-area .text-messages .activation-time svg {
  vertical-align: middle;
  margin-left: 7px;
}

.InboxModule-container-parent-class .chatbox-area .text-messages .activation-time i {
  font-size: 13.5px;
  color: #27AE60;
  vertical-align: middle;
  margin-right: 7px;
}

.InboxModule-container-parent-class .chatbox-area .text-messages .activation-time i.voice {
  color: #4F4F4F;
}

/* Owner Contact Component CSS */

.InboxModule-container-parent-class .owner-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 20px 10px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 10px;
}

.InboxModule-container-parent-class .owner-contact .owner-brand {
  padding: 20px 0 20px 0;
}

.InboxModule-container-parent-class .owner-contact .owner-brand img {
  width: 60px;
  height: 60px;
  display: block;
  background: #133159;
  padding: 10px;
  border-radius: 30px;
}

.InboxModule-container-parent-class .owner-contact .owner-identity {
  margin-bottom: 8px;
}

.InboxModule-container-parent-class .owner-contact .owner-identity h1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #133159;
  margin: 0;
}

.InboxModule-container-parent-class .owner-contact .owner-identity h2 {
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #546376;
}

.InboxModule-container-parent-class .owner-contact .owner-communication {
  margin-bottom: 8px;
}

.InboxModule-container-parent-class .owner-contact .owner-communication p {
  color: #546376;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 3px 0;
}

.InboxModule-container-parent-class .owner-contact .owner-communication span {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #8896A8;
}

.InboxModule-container-parent-class .owner-contact .edit-information {
  padding-bottom: 15px;
}

.InboxModule-container-parent-class .owner-contact .edit-information a {
  color: #3C7EF3;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.InboxModule-container-parent-class .owner-contact .edit-information a i {
  font-size: 8px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  display: inline-block;
  text-align: center;
  line-height: 16px;
  background: rgba(60, 126, 243, 0.1);
  margin-right: 8px;
  color: #3C7EF3;
}

/* lead owner component css */

/* .lead-owner {
  padding: 10px;
  background: #133159;
  margin: 0 10px 10px 10px;
  border-radius: 10px 10px 0px 0px;
}

.lead-owner svg {
  margin-left: 21px;
  margin-right: 18px;
  vertical-align: middle;
}

.lead-owner span {
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
  color: #fff;
  margin-right: 0;
}

.lead-owner input[type="text"] {

  height: 30px;
  background: #fff;
  border-radius: 6px;
  font-weight: 500;
  font-size: 13px;
  line-height: 34px;
  color: #3C7EF3;
  padding: 0 0 0 11px;
  margin: 0;
  width: 115px;
} */

/* collaborators component css */

/* .collaborators .collapsible {
  border: 0;
  margin: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
} */

/* .collaborators .collapsible-header { */

/* color: #fff;
  border: 0;
  background: #133159;
  margin: 0 10px 10px 10px;
  border-radius: 10px 10px 0px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px;
}

.collaborators .collapsible-header .left-icons {
  padding-left: 21px;
}

.collaborators .collapsible-header .left-icons svg {
  margin-right: 20px;
}


.collaborators .collapsible-header .left-icons span.title {
  margin-right: 4px;
}

.collaborators .collapsible-header .left-icons span.number-badge {
  width: 26px;
  height: 26px;
  background: #fff;
  color: #3C7EF3;
  border-radius: 13px;
  text-align: center;
  line-height: 28px;
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
}

.collaborators .collapsible-header .right-icons {
  padding-right: 10px;
}

.collaborators .collapsible-header .right-icons i {
  width: 26px;
  height: 26px;
  background: #fff;
  color: #3C7EF3;
  border-radius: 13px;
  text-align: center;
  line-height: 26px;
  display: inline-block;
  font-weight: 500;
  margin-right: 20px;
  font-size: 14px;
}

.collaborators .collapsible-body {
  border: 0;
  -webkit-box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.04);
  margin: 0 10px 10px 10px;
  border-radius: 0px 0px 6px 6px;
  padding: 0 0 14px 0;
}

.collaborators .collapsible-body .listing {
  padding-left: 24px;
  padding-right: 26px;
  padding-bottom: 4px;
}

.collaborators .collapsible-body .listing span {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #546376;
  letter-spacing: 0.01em;
}

.collaborators .collapsible-header .left-icons img {
  margin-right: 20px;
}

/* Deals component css */

/* .deals .collapsible-body {
  padding-bottom: 12px;
}

.deals .collapsible-header .left-icons span.title {
  margin-right: 14px;
}

.deals .collapsible-body .listing {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 18px;
}

.deals .collapsible-body .listing a {
  background: #E5E8EF;
  color: rgba(19, 49, 89, 0.65);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  height: 24px;
  display: block;
  width: 89px;
  text-align: center;
}

.deals .collapsible-body .listing a:first-child {
  background: #3C7EF3;
  color: #fff;
}

.deals .collapsible-body .listing-content {
  padding-left: 25px;
  padding-right: 0;
  padding-bottom: 12px;
}

.deals .collapsible-body .listing-content span {
  margin-right: 15px;
}

.deals .collapsible-body .listing-content a {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 0 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  width: 71px;
  height: 24px;
  display: inline-block;
  text-align: center;
}

.deals .collapsible-body .listing-content a.won {
  color: #6FCF97;
  border: 1px solid #6FCF97;
  margin-right: 5px;
}

.deals .collapsible-body .listing-content a.lost {
  color: #EB5757;
  border: 1px solid #EB5757;
}

.deals .collapsible-body .listing-content a.lost img {
  vertical-align: middle;
}

/* Campaigns component css */

.InboxModule-container-parent-class .campaigns .collapsible-header .left-icons span.title {
  margin-right: 21px;
}

.InboxModule-container-parent-class .campaigns .collapsible-header .left-icons img {
  margin-right: 14px;
}

.InboxModule-container-parent-class .campaigns .collapsible-body {
  padding: 0 0 7px 0;
}

.InboxModule-container-parent-class .campaigns .collapsible-body .listing {
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 16px;
}

d.campaigns .collapsible-body .listing a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #6FCF97;
  border-radius: 10px;
  font-size: 10px;
  line-height: 16px;
  color: #fff;
  padding: 0 4px;
  width: 98px;
  height: 20px;
}

.InboxModule-container-parent-class .campaigns .collapsible-body .listing a i:first-child {
  font-size: 14px;
  padding-right: 7px;
}

.InboxModule-container-parent-class .campaigns .collapsible-body .listing a i:last-child {
  font-size: 16px;
  padding-left: 4px;
}

/* Completed forms component css */

.InboxModule-container-parent-class .completed-forms .collapsible-header .left-icons span.number-badge {
  margin-left: 22px;
}

.InboxModule-container-parent-class .completed-forms .collapsible-header {
  margin-bottom: 6px;
}

.InboxModule-container-parent-class .completed-forms .collapsible-body .form-name {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 5px;
}

.InboxModule-container-parent-class .completed-forms .collapsible-body .form-name span {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.InboxModule-container-parent-class .completed-forms .collapsible-body .form-name span.name {
  color: #133159;
}

.InboxModule-container-parent-class .completed-forms .collapsible-body .form-name span.date {
  color: #546376;
}

.InboxModule-container-parent-class .completed-forms .collapsible-body {
  padding: 0 0 7px 0;
}

/**************************************
Inbox Expand SMS-2 CSS with others pages  
**************************************/

.InboxModule-container-parent-class .info-links {
  padding: 30px 20px 0 20px;
}

.InboxModule-container-parent-class .info-links span {
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-right: 10px;
  margin-top: -5px;
  cursor: pointer;
}

.InboxModule-container-parent-class .info-links span i.close {
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background: #FF264A;
  color: #fff;
  font-size: 10px;
  line-height: 22px;
  box-shadow: 0px 0px 1px 1px #ff264a;
  display: block;
  text-align: center;
}

.InboxModule-container-parent-class .info-links ul {
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.InboxModule-container-parent-class .info-links ul li {
  display: inline-block;
  margin-bottom: 5px;
}

.InboxModule-container-parent-class .info-links ul li a {
  border: 1px solid rgba(60, 126, 243, 0.1);
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  padding: 0 12px;
  margin: 0 -2px 0 -2px;
  font-weight: normal;
  font-size: 12px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.InboxModule-container-parent-class .info-links .tabs .tab a {
  color: #133159;
  text-transform: capitalize;
  font-size: 12px;
  padding: 0;
}

.InboxModule-container-parent-class .info-links ul li a:hover,
.info-links ul li a.active {
  color: #3C7EF3 !important;
}

.InboxModule-container-parent-class .info-links .ul li a,
.info-links .ul li a.active svg path,
.info-links .ul li a.active svg ellipse {
  color: #3c7ef3;
  fill: #3c7ef3 !important;
}

.InboxModule-container-parent-class .info-links ul li a svg {
  vertical-align: middle;
  margin-right: 5px;
}

.InboxModule-container-parent-class .info-links ul li a img {
  margin-right: 8px;
  vertical-align: middle;
}

.InboxModule-container-parent-class .info-links .tabs .indicator {
  height: 0;
  background-color: transparent;
}

.InboxModule-container-parent-class .info-links .tabs .tab a:focus,
.info-links .tabs .tab a:focus.active {
  background-color: transparent;
  outline: none;
}

.InboxModule-container-parent-class .info-links .tabs .tab a:focus,
.info-links .tabs .tab a:focus.active {
  color: #3c7ef3;
}

.InboxModule-container-parent-class .info-links .tabs {
  height: 40px;
  margin-bottom: 5px;
}

.InboxModule-container-parent-class .info-links .tabs .tab {
  height: 40px;
  line-height: 40px;
}

.InboxModule-container-parent-class .info-links .tabs .tab a:focus,
.info-links .tabs .tab a:focus.active svg path,
.info-links .tabs .tab a:focus.active svg ellipse {
  color: #3c7ef3;
  fill: #3c7ef3;
}

.InboxModule-container-parent-class .info-links ul li:nth-last-of-type(2) a {
  border-radius: 0px 10px 10px 0px;
}

.InboxModule-container-parent-class .update__empty_inbox .info-links ul li:nth-last-of-type(2) a {
  border-radius: 0;
}

.InboxModule-container-parent-class .info-links ul li:first-child a {
  border-radius: 10px 0px 0px 10px;
}

.InboxModule-container-parent-class .info-links ul li:last-child a {
  border-radius: 0px 10px 10px 0px;
}

/* Catagory area css */

.InboxModule-container-parent-class .catagory-area .v2__width200px {
  max-width: 200px;
  width: 100%;
}

.InboxModule-container-parent-class .catagory-area {
  padding: 0 20px;
}

.number-input-field {
  position: relative;
}

.number-input-field svg {
  position: absolute;
  right: 30px;
  top: 15px;
}

.number-input-field .select-wrapper .caret {
  display: none;
}

.number-input-field .select-wrapper input.select-dropdown {
  font-size: 14px;
  line-height: 21px;
  color: #133159;
  border: 1px solid rgba(60, 126, 243, 0.1);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0 15px;
  height: 39px;
  font-weight: 400;
}

.personiliz-input-field .select-wrapper input.select-dropdown {
  padding: 0 50px;
}

.personiliz-input-field svg {
  right: 45px;
  top: 16px;
}

.InboxModule-container-parent-class .email-input-filed .select-wrapper input.select-dropdown {
  font-size: 14px;
  line-height: 28px;
  color: #546376;
}

.InboxModule-container-parent-class .email-input-filed svg {
  right: 20px;
  top: 17px;
}

.InboxModule-container-parent-class .personiliz-input-field-2 .select-wrapper input.select-dropdown {
  font-size: 14px;
  line-height: 28px;
  color: #546376;
}

.InboxModule-container-parent-class .personiliz-input-field-2 svg {
  right: 20px;
  top: 17px;
}

.InboxModule-container-parent-class .subject-input-field input[type="text"] {
  border: 1px solid #E5E8EF;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  height: 40px;
  font-size: 14px;
  line-height: 28px;
  color: #546376;
  padding-left: 10px;
  margin-bottom: 0;
  box-shadow: none;
}

.tiny-mce {
  padding: 0 20px;
}

.InboxModule-container-parent-class .send-button-area {
  padding: 0 20px;
  margin-top: 10px;
}

.InboxModule-container-parent-class .quick-reply a {
  width: 168px;
  height: 40px;
  border: 1px solid rgba(60, 126, 243, 0.1);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #133159;
  display: inline-block;
  line-height: 40px;
  text-align: center;
}

.InboxModule-container-parent-class .quick-reply svg {
  vertical-align: middle;
  margin-right: 5px;
}

.InboxModule-container-parent-class .sendemail {
  margin: 0 0 10px 0;
}

.InboxModule-container-parent-class .sendemail ul li {
  display: inline-block;
}

.InboxModule-container-parent-class .sendemail ul li a {
  padding: 0 8px;
  display: inline-block;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  background: rgba(60, 126, 243, 0.1);
  color: #3C7EF3;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.InboxModule-container-parent-class .sendemail ul li a.active,
.sendemail ul li a:hover {
  background-color: #3C7EF3;
  color: #fff;
}

/* Audio Design CSS */

.InboxModule-container-parent-class .audio-voice .card {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 20px 8px 20px 8px;
  margin: 3px 20px 0 20px;
  background: rgba(60, 126, 243, 0.1);
  border-radius: 10px;
}

.InboxModule-container-parent-class .audio-voice .card p.title {
  margin: 0 0 18px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #133159;
  letter-spacing: 0.01em;
}

.InboxModule-container-parent-class .audio-voice .card p.alert-title {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #FF6B6B;
  margin: 15px 0 0 0;
}

.InboxModule-container-parent-class .audio-voice .card p.alert-title i {
  vertical-align: middle;
  margin-right: 8px;
  color: #FF6B6B;
}

.InboxModule-container-parent-class .audio-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 35px;
}

.InboxModule-container-parent-class .audio-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.InboxModule-container-parent-class .icon-record {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 40px;
}

.InboxModule-container-parent-class .icon-record a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  background: rgba(60, 126, 243, 0.1);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 20px;
  margin-bottom: 5px;
  align-items: center;
}

.InboxModule-container-parent-class .icon-record a img {}

.InboxModule-container-parent-class .icon-record span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  color: #546376;
  line-height: 26px;
}

.voice_number_select {
  padding: 5px;
  border-radius: 5px;
  margin: 7px 20px;
  border: 1px solid rgba(60, 126, 243, 0.1);
}

/* Direct Mail CSS */

.InboxModule-container-parent-class .direct-mail .card {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 10px 17px 10px;
  margin: 3px 20px 0 20px;
  background: rgba(60, 126, 243, 0.1);
  border-radius: 10px;
}

.InboxModule-container-parent-class .direct-mail .card p {
  margin: 5px 0 10px 0;
  letter-spacing: 0.01em;
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px
}

.InboxModule-container-parent-class .direct-mail .card-listing {
  padding-top: 6px;
  padding-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
}

.InboxModule-container-parent-class .direct-mail .card-listing a {
  background: rgba(60, 126, 243, 0.1);
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
  width: 170px;
  margin-left: 5px;
  display: inline-block;
  height: 40px;
  margin-right: 5px;
  color: #3C7EF3;
  margin-bottom: 5px;
}

.InboxModule-container-parent-class .tox .tox-statusbar {
  display: none !important;
}

.InboxModule-container-parent-class .tox-tinymce {
  border-radius: 10px !important;
}

audio,
video {
  max-width: 100%;
  /*margin-top: 15px;*/
}

.InboxModule-container-parent-class .audio-setup {
  margin-left: 0;
}

.InboxModule-container-parent-class .audio-setup ul {
  padding: 0;
  margin: 0;
}

.InboxModule-container-parent-class .audio-setup ul li {
  display: inline-block;
  list-style-type: none;
  margin-right: 7px;
  vertical-align: middle;
}

.InboxModule-container-parent-class .audio-setup ul li.range-field {
  width: 85px;
}

input[type=range] {
  -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  background: #3C7EF3;
  border: none;
  border-radius: 12px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: #fff;
  margin-top: -5px;
  border: 2px solid #fff;
}

input[type=range]:focus {
  outline: none;
  background: #D9E6FD;
}

input[type=range] {
  /* fix for FF unable to apply focus style bug  */
  border: 1px solid transparent;
  vertical-align: middle;
  margin: 0;
  /*required for proper track sizing in FF*/
  width: 100%;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  background: #3C7EF3;
  border: none;
  border-radius: 12px;
}

input[type=range]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border: 2px solid #fff;
  border-radius: 100%;
  background: #3C7EF3;
}

/*hide the outline behind the border*/

input[type=range]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type=range]:focus::-moz-range-track {
  background: #D9E6FD;
}

input[type="range"]::-moz-range-progress {
  background-color: #3C7EF3;
  height: 10px;
  border-radius: 10px;
}

input[type="range"]::-moz-range-track {
  background-color: #D9E6FD;
  height: 10px;
  border-radius: 10px;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  /*remove background color from the track, instead backgrond we'll use ms-fill-lower and ms-fill-upper */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 10px 0;
  /*remove default tick marks*/
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #3C7EF3;
  border-radius: 12px;
}

input[type=range]::-ms-fill-upper {
  background: #dddddd;
  border-radius: 12px;
}

input[type=range]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: #3C7EF3;
  border: 2px solid #fff;
}

input[type=range]:focus::-ms-fill-lower {
  background: #3C7EF3;
}

input[type=range]:focus::-ms-fill-upper {
  background: #D9E6FD;
}

/*****************************************
inbox right side slide
****************************************/

.InboxModule-container-parent-class .right_sidebar_active {}

.main__content .InboxModule-container-parent-class .main-inbox_column {
  transition: all 0.5s ease-in-out;
  width: 75% !important;
}

.InboxModule-container-parent-class .inbox-right-side {
  width: 0%
}

.right_sidebar_active .InboxModule-container-parent-class .main-inbox_column {
  width: 50% !important;
}

.right_sidebar_active .InboxModule-container-parent-class .inbox-right-side {
  width: 25%;
}

.InboxModule-container-parent-class .auto-complete-search>div>div {
  z-index: 9999999999;
}

.InboxModule-container-parent-class .auto-complete-custom-wrapper {
  padding: 5px 10px;
  cursor: pointer;
}

.InboxModule-container-parent-class .auto-complete-custom-wrapper .auto-complete-name-label-span {
  padding-right: 10px;
}

.InboxModule-container-parent-class .auto-complete-custom-wrapper .auto-complete-number-label {
  color: grey;
}

.InboxModule-container-parent-class .chat__bottom_wrapper.tab .tab-pane {
  display: none;
}

.InboxModule-container-parent-class .chat__bottom_wrapper.tab .tab-pane.active {
  display: block;
}

.InboxModule-container-parent-class .inbox_loader {
  height: calc(100vh - 380px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
}

.InboxModule-container-parent-class .filtering-buttons .auto-complete-search input {
  height: 39px;
  margin: 0px;
  display: flex;
}

.InboxModule-container-parent-class .filtering-buttons .auto-complete-search input,
body input.input__global_style {
  border: 1px solid #e5e5e5 !important;
  border-radius: 4px;
  padding: 9px 10px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: unset !important;
}

.InboxModule-container-parent-class .v2_create__new_item {
  width: calc(100% - 50px);
  margin-left: 10px;
}

#preview-holder {
  position: relative;
  /* width: 50px; */
  height: 65px;
}

#preview-holder .close {
  color: red;
  font-size: 12px;
  background: white;
  right: 0;
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
}

#InboxModule-container {
  overflow: hidden;
  height: 100%;
  max-width: calc(100vw - 215px);
}

.active__minimal_sidebar #InboxModule-container{
  max-width: calc(100vw - 110px);
}

.massaging_service {
  max-width: 1170px;
  background: var(--gray_light);
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  left: unset;
  top: 80px !important;
  right: 0 !important;
  position: fixed;
  max-height: 80%;
  overflow: hidden;
}

.massaging_service.v2.message-template-inbox-module {
  max-height: calc(100vh - 100px);
  height: 100%;
}

.top_content {
  border-bottom: 1px solid #E7EFF0;
}

.sub_title h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0.03em;
  color: var(--dark_blue);
  margin: 0 0 0 10px;
}

.cross_button {
  background: var(--red);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 15px;
}

.manage_modal_input_fields {
  padding-right: 50px;
}

.search_input {
  position: relative;
  margin-left: auto;
  margin-right: 20px;
}

.search_input input {
  background: #FFFFFF;
  border: 1px solid rgba(19, 49, 89, 0.35) !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: rgba(19, 49, 89, 0.35);
  padding: 15px 57px 15px 20px !important;
  margin: 0px !important;
  height: 40px !important;
}

.search_input .search_icon {
  position: absolute;
  top: 5px;
  right: 9px;
  border-left: 1px solid rgba(19, 49, 89, 0.35);
  padding-left: 6px;
  height: 30px;
  padding-top: 5px;
  cursor: pointer;
}

.manage_modal_drp {
  margin-bottom: 7px !important;
  margin-right: 20px;
}

.manage_modal_drp .drp_arrow_icon {
  position: absolute;
  right: 12px;
  top: 11px;
}

.manage_modal_input_fields a {
  background: rgb(60, 126, 243);
  border-radius: 5px;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: white;
  padding: 5px 32px;
}

.video_thumbnails_scroll.awesome__scroll_bar {
  height: 500px;
  overflow-y: auto;
  padding: 0px !important;
}

.video_thumbnails.awesome__scroll_bar {
  margin: 0 17px;
  padding-bottom: 80px;
  overflow-y: auto;
  max-height: calc(100vh - 285px);
}

.modal_thumbnail .manage_modal_card {
  background: #F6F7FB !important;
  border-radius: 5px;
  padding: 15px !important;
  position: relative;
  overflow: hidden;
  padding-bottom: 25px !important;
  height: 320px !important;
  margin: 0 0 20px 0 !important;
  border: 1px solid #ddd;
}

body .update_quick_reply.quick-reply a {
  height: 40px !important;
  line-height: 40px !important;
}

.card .card-image img {
  display: block;
  border-radius: 2px 2px 0 0;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.manage_modal_card_content .card-title {
  font-weight: 500;
  font-size: 10.6978px;
  line-height: 18px;
  color: #FFFFFF !important;
  position: relative !important;
  margin: unset !important;
  bottom: 30px !important;
  padding: 15px !important;
}

.modal_thumbnail .manage_modal_card a {
  background: #3C7EF3;
  font-weight: 500;
  font-size: 13.7254px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: center;
  padding: 10px 20px;
  text-transform: capitalize;
  border-radius: 5px;
}

.manage_modal_card p.template-type {
  font-weight: normal;
  font-size: 13.7254px;
  line-height: 24px;
  color: #FFFFFF;
  background: #133159;
  text-transform: capitalize;
  position: absolute;
  transform: rotate(45deg);
  top: 14px;
  right: -46px;
  padding: 3px 50px;
  box-shadow: 0 3px 10px -5px rgb(0 0 0);
}

.manage_modal_card p.alt-template-type {
  padding: 3px 70px;
}

.selected_audio_file {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(60, 126, 243, 0.1);
  border-radius: 10px;
  padding: 70px;
}

.selected_audio_file audio {
  margin-top: 0px;
  margin-right: 10px;
}

.bg-light-blue-10 {
  background: var(--light-blue-10);
}

.radius-10 {
  border-radius: 10px !important;
}

.text-red {
  color: var(--red);
}

.bg-light-blue {
  background: var(--light_blue);
}

.radius-5 {
  border-radius: 5px !important;
}

.close_btn {
  background: var(--red);
  padding: 13px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--white);
  border-radius: 5px;
}

/* .close_btn span {
  display: inline-block;
  background: #fff;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  margin-right: 10px;
} */

.massage_send_close_button .send_massage {
  background: #3C7EF3;
  border-radius: 5px;
  padding: 13px 30px;
  color: var(--white);
  margin-right: 30px;
  text-transform: capitalize;
  font-size: 16px;
}

.share_campaign_cross_button {
  background: var(--red);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  cursor: pointer;
}

.InboxModule-container-parent-class .card-panel.message-discussion.box-shadow-none.activeTab {
  border: 1px solid #27AE60
}

.position-relative {
  position: relative;
}

/*.inbox-conv-wrap {*/

/*  height: calc(100% - 60px);*/

/*}*/

.InboxModule-container-parent-class .chatbox-area.inbox-conv-wrap.full_area_chat_box {
  height: calc(100% - 70px)
}

.InboxModule-container-parent-class .chatbox-area.inbox-conv-wrap {
  height: calc(100% - 375px);
}

.InboxModule-container-parent-class .chat__bottom_wrapper.chat__wrapper-main {
  position: absolute;
  width: 98%;
  /*bottom: 0px;*/
  bottom: 20px;
  height: max-content;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.InboxModule-container-parent-class .chatbox-area.inbox-conv-wrap.inbox-note-active,
.chatbox-area.inbox-conv-wrap.inbox-chat-active {
  height: calc(100% - 175px);
}

.InboxModule-container-parent-class .chatbox-area.inbox-conv-wrap.inbox-directmail-active {
  height: calc(100% - 320px);
}

.InboxModule-container-parent-class .chatbox-area.inbox-conv-wrap.inbox-voice-active {
  height: calc(100% - 330px);
}

.InboxModule-container-parent-class .chatbox-area.inbox-conv-wrap.inbox-text-active {
  height: calc(100% - 228px);
}

.InboxModule-container-parent-class .inbox-conv-wrap .chatbox-area.inbox-conv-wrap.inbox-email-active {
  height: calc(100% - 455px);
}

/* Inbox Update*/

.p-t-10 {
  padding-top: 10px;
}

.InboxModule-container-parent-class .replay_text_warp .chat-text {
  display: inline-flex;
  margin-left: auto;
}

.InboxModule-container-parent-class .replay_text_warp {
  width: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  float: right;
}

.InboxModule-container-parent-class .chatbox-area .text-messages .replay_text_warp .activation-time {
  margin-right: auto;
}

.InboxModule-container-parent-class .chatbox-area .activation-time {
  margin-top: 8px;
}

.InboxModule-container-parent-class .back_to_chats {
  display: none;
  width: 30px;
  height: 30px;
  line-height: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}

.InboxModule-container-parent-class .closeRightSidebar,
li .keyboard_arrow {
  display: none;
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
  border: 1px solid #133159;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s linear;
}

body .keyboard_arrow.keyboard_left_arrow {
  margin-right: 0;
}

.InboxModule-container-parent-class .customSelectLi input {
  height: unset !important;
}

/* 20.9.21 */
.InboxModule-container-parent-class .update__empty_inbox .tiny-mce {
  padding: 0;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  display: none;
}

.InboxModule-container-parent-class .update__empty_inbox .send-button-area {
  padding: 0;
}

.InboxModule-container-parent-class .subject__personalize_wrapper .subject-input-field input[type="text"]::placeholder {
  color: #272727;
}

.InboxModule-container-parent-class .update__empty_inbox .catagory-area {
  padding: 0;
  margin-top: 5px;
}

.InboxModule-container-parent-class .update__empty_inbox .info-links .tabs {
  padding: 0 33px 0 0;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.update__empty_inbox .MuiSelect-select.MuiSelect-select {
  font-size: 14px;
}

.InboxModule-container-parent-class .update__empty_inbox .number-input-field>svg {
  display: none;
}

.InboxModule-container-parent-class .update__empty_inbox.email-input-filed svg {
  display: none;
}

.InboxModule-container-parent-class .update__empty_inbox .number-input-field svg {
  position: static;
  top: unset;
  right: unset;
}

.massaging_service.v2 .search_input {
  margin: 0;
}

.notetab_text_field textarea.note_tab_textarea,
.chatTab_text_filed textarea.chat_textarea {
  height: 245px !important;
}

textarea.materialize-textarea,
.borderStyle1px {
  border: 1px solid rgba(19, 49, 89, 0.35) !important;
  border-radius: 5px !important;
  padding: 0 15px !important;
  height: 197px !important;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box !important;
}

.flex1 {
  flex: 1;
}

.InboxModule-container-parent-class .v3_update__empty_inbox .v3_personalize {
  height: 40px !important;
}

.InboxModule-container-parent-class .v3_update__empty_inbox .send-message-btn {
  padding: 0;
}

.InboxModule-container-parent-class .update__empty_inbox textarea.materialize-textarea::placeholder {
  font-size: 12px;
  color: rgb(71 39 55);
}

/* .InboxModule-container-parent-class .update__empty_inbox textarea.materialize-textarea {
  margin: 8px 0 0;
  padding-top: 13px !important;
  border-color: rgb(229 229 229) !important;
  color: rgb(71 39 55);
  display: block;
  font-size: 12px;
  box-shadow: unset !important;
} */
.massaging_service.v2 .input-field.manage_modal_drp .MuiSelect-select {
  font-size: 14px;
}

.massaging_service.v2 .input-field.manage_modal_drp {
  border: 1px solid rgba(19, 49, 89, 0.35) !important;
  border-radius: 5px !important;
  padding: 0 15px;
  margin: 0 20px !important;
  max-width: 250px;
  width: 100%;
}

.update__empty_inbox .quick-reply a {
  border-color: rgba(15, 47, 88, 0.35);
  border-radius: 6px;
}

.massaging_service.v2 .MuiInput-underline:before,
.massaging_service.v2 .MuiInput-underline:after {
  display: none;
}

.massaging_service.v2 .search_input input::placeholder {
  color: #a5a5a8;
}

.massaging_service.v2 .search_input input {
  padding: 0 10px !important;
  font-size: 14px !important;
  color: #a5a5a8;
}

.massaging_service.v2 .manage_modal_input_fields {
  margin: 0 0 15px;
  padding: 15px 20px 15px 35px;
  border-bottom: 1px solid #ddd;
}

.massaging_service.v2 .manage_modal_drp input {
  height: auto !important;
  margin: 0;
  padding: 7px !important;
}

.InboxModule-container-parent-class .update__empty_inbox .input-field.number-input-field.email-input-filed input {
  height: auto;
}

.InboxModule-container-parent-class .subject__personalize_wrapper {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 4px 5px;
  display: inline-flex;
  align-items: center;
}

.subject__personalize_wrapper .subject-input-field input[type="text"] {
  /* all: unset; */
  border: 0 !important;
  box-shadow: unset !important;
  height: auto;
  margin: 0 !important;
}

.InboxModule-container-parent-class .update__editor_wrapper {
  margin-top: 8px;
}

.update__editor_wrapper .tox.tox-tinymce {
  border: 1px solid #ccc !important;
  border-radius: 6px !important;
}

.InboxModule-container-parent-class .update__empty_inbox .tox .tox-toolbar__primary {
  border-radius: 0 !important;
  border: 0 !important;
  border-right: 1px solid #e5e8ef !important;
}

.InboxModule-container-parent-class .update__empty_inbox .quick-reply {
  /* min-width: 130px; */
}

.InboxModule-container-parent-class .update__empty_inbox .quick-reply svg {
  margin-right: 10px;
}

.InboxModule-container-parent-class .update__empty_inbox .info-links ul li a svg {
  margin-right: 6px;
}

.update__empty_inbox .ppc__select_owner .MuiSelect-select {
  height: 40px !important;
}

.InboxModule-container-parent-class .update__empty_inbox .number-input-field.assign-stage-single-item {
  max-width: 220px;
  width: 100%;
}

.InboxModule-container-parent-class .update__empty_inbox .second__step__area__select {
  border: 1px solid rgb(229 229 229) !important;
}

.massaging_service.update_v2 .selects_fields .borderStyle1px {
  background: transparent !important;
  border-color: #a6b2c3 !important;
  box-shadow: unset !important;
  color: #202021;
  padding: 0 15px !important;
  font-size: 15px !important;
}

.massaging_service.update_v2 .selects_fields .borderStyle1px::placeholder {
  color: #202021;
}

.massaging_service.update_v2 .number-input-field.alt svg {
  position: static;
  right: unset;
  top: unset;
}

.massaging_service.update_v2 .number-input-field.alt input {
  height: auto;
}

.massaging_service.update_v2 .add__cc_bcc_btns a {
  height: 40px;
}

.borderStyleWrapper .borderStyle1px input {
  height: auto;
}

.borderStyleWrapper .borderStyle1px input {
  height: 100%;
  margin: 0;
  border: 0 !important;
  box-shadow: unset !important;
}

.borderStyleWrapper .form-control.borderStyle1px.react-multi-email>div {
  border: 1px solid #777;
  border-radius: 10px;
  padding: 0px 5px;
  font-size: 13px;
  margin-right: 6px;
  display: inline-flex;
  align-items: center;
}

.paddingX20px {
  padding: 0 20px !important;
}

.borderStyle1px.alt .MuiSelect-select.MuiSelect-select {
  padding-right: 0;
}

.borderStyleWrapper .form-control.borderStyle1px.react-multi-email>div>span {
  font-size: 20px;
  margin-left: 10px;
  line-height: 0;
  padding-top: 1px;
  display: inline-block;
}

div.card-panel:nth-child(8)>div:nth-child(1)>div:nth-child(2)>div:nth-child(1)>div:nth-child(1) {
  width: calc(100% - 70px);
}

/* .InboxModule-container-parent-class .message-discussion .user-message span.full-name {
  width: 100%;
} */
.InboxModule-container-parent-class .user-short-name {
  width: 40px !important;
  flex-shrink: 0;
}

.InboxModule-container-parent-class .fullNameWrapper {
  width: calc(100% - 65px);
}

.InboxModule-container-parent-class .message-discussion .user-message {
  width: calc(100% - 54px) !important;
}

.InboxModule-container-parent-class .MuiButtonBase-root {
  display: flex;
}

/* Update inbox right sidebar height and add scroll */
@media(min-width: 993px) {
  .InboxModule-container-parent-class .v2_lead_owner_collapsible.lead_owner_collapsible.white.inbox-right-side {
    height: calc(100vh - 140px);
  }

  .InboxModule-container-parent-class .v2_lead_owner_collapsible.lead_owner_collapsible.white.inbox-right-side ul.collapsible {
    height: calc(100% - 255px);
    margin: 0;
    padding: 0 10px 0 0;
  }

  .InboxModule-container-parent-class .v2_lead_owner_collapsible.lead_owner_collapsible .awesome__scroll_bar::-webkit-scrollbar {
    width: 10px;
  }
}

@media(max-width: 1200px) {

  .InboxModule-container-parent-class .update__empty_inbox textarea.materialize-textarea,
  .borderStyle1px {
    padding: 0 10px !important;
  }

  .InboxModule-container-parent-class .update__empty_inbox .tox .tox-toolbar-overlord {
    width: 100%;
    overflow-x: auto;
    display: flex;
  }

  .send-message-btn a {
    padding: 7px 15px;
  }
}

@media(min-width: 1921px) {
  .InboxModule-container-parent-class .inbox-main-section {
    height: calc(100vh - 235px);
  }
}

.InboxModule-container-parent-class .moreLoadingInbox {
  padding-top: 10px;
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
  color: #e5e8ef;
}

.opacity-5 {
  opacity: 0.5;
}


.InboxModule-container-parent-class .user-area span.new {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #ff0606;
  color: #fff;
  font-size: 8px;
  font-weight: 900;
  padding: 0 5px;
  border-radius: 5px;
}

.InboxModule-container-parent-class .load-more-inbox-thread {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00000073;
}

.InboxModule-container-parent-class .m-inbox-left-side-search::placeholder {
  color: #000000;
  opacity: 1;
  /* Firefox */
}

.InboxModule-container-parent-class .m-inbox-left-side-search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
}

.InboxModule-container-parent-class .m-inbox-left-side-search::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
}

.InboxModule-container-parent-class .m-inbox-filter__placeholder {
  color: #000000 !important;
}

@media(min-width: 2000px) {
  .InboxModule-container-parent-class .m-inbox-filter__placeholder {
    font-size: 16px !important;
  }

  .InboxModule-container-parent-class .m-inbox-left-side-search {
    font-size: 16px !important;
  }
}

.InboxModule-container-parent-class .disable-action-option {
  opacity: 0.3;
  cursor: not-allowed;
}

.InboxModule-container-parent-class .disable-action-option a {
  cursor: not-allowed !important;
}

@media(max-width: 1600px) {
  .InboxModule-container-parent-class .chat__text-wrap {
    padding-left: 45px !important;
  }
}

@media(min-width: 1600px) {
  .InboxModule-container-parent-class .chat__text-wrap {
    padding-left: 45px !important;
  }
}

.m-inbox-no-template {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InboxModule-container-parent-class .search-box-for-global input[type="search"] {
  Height: 35.29px;
  font-family: var(--poppins);
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  padding: 0 12px;
}

.InboxModule-container-parent-class .search-box-for-global {
  padding: 20px 20px 18px;
}

.InboxModule-container-parent-class .search-box-for-global input[type="search"]~.material-icons {
  right: 23px;
  top: 23px;
  color: #133159;
  font-size: 17px;
}

/* direct mail css */
.card.direct-mail-box-inbox {
  /* height: 312px; */
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media(max-width: 1366px) {
  .m-inbox-filter__value-container {
    font-size: 10px !important;
  }

  .InboxModule-container-parent-class .message-links-tab .tab a {
    font-size: 11px;
  }

  .active__minimal_sidebar .InboxModule-container-parent-class .message-links-tab .tab a {
    font-size: 12px;
  }
}

.inbox-note-bg {
  /* background-color: #f1e472 !important; */
  background-color: var(--yellow-10) !important;
}

.react-confirm-alert-button-group>button {
  background: #333 !important;
}

.react-confirm-alert-button-group>button:active {
  background: #333 !important;
}

/* new send option bottom part css */

.inbox__text__msg__wr {}

.inbox__text__msg__wr .inbox__all__btn__group_single {
  display: flex;
  align-items: center;
  border: 1px solid rgba(19, 49, 89, 0.35);
  padding: 5px 8px;
  border-radius: 6px;
  cursor: pointer;
  grid-gap: 5px;
  position: relative;
}

.inbox__text__msg__wr .inbox__all__btn__group_single__alt {
  display: flex;
  align-items: center;
  /* border: 1px solid rgba(19, 49, 89, 0.35); */
  padding: 5px 8px;
  border-radius: 6px;
  cursor: pointer;
  grid-gap: 5px;
  position: relative;
}

.inbox__text__msg__wr .inbox__text__msg__container {
  border: 1px solid rgba(19, 49, 89, 0.35);
}

.inbox__text__msg__wr .inbox__all__btn__group_single span {
  display: inline-flex;
  font-size: 12px;
  color: var(--dark_blue);
}

.inbox__text__msg__wr .inbox__all__btn__group_single span.inbox__max__file__text {
  position: absolute;
  right: -86px;
  font-size: 10px !important;
  color: #f00;
  font-family: var(--poppins);
}

.inbox__text__msg__wr .inbox__all__btn__group {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

.inbox__text__msg__wr .inbox__text__msg__area__wr {
  padding: 16px;
  border: 1px solid rgb(229 229 229);
  margin: 16px 0;
  border-radius: 6px;
}

.inbox__text__msg__wr .inbox__tiny__text__area {
  margin: 8px 0 0;
  padding-top: 13px !important;
  border-color: rgba(0, 0, 0, 0.0) !important;
  color: rgb(71 39 55);
  display: block;
  font-size: 12px;
  box-shadow: unset !important;
  height: 100px;
  resize: none;
}

.inbox__text__msg__wr .inbox__tiny__text__area:focus {
  outline: none;
}


.inbox__text__msg__wr .ppc__select_owner .second__step__area__select {
  max-width: 200px;
  width: 100%;
}

.inbox__text__msg__wr .inbox__tiny__mce__wr {
  display: flex;
  margin: 10px 0 0 0;
}

.inbox__text__msg__wr .inbox__tiny__mce__wr .inbox__tiny__mce__container {
  flex-basis: 100%;
  position: relative;
}

.inbox__text__msg__wr .inbox__tiny__mce__upload__images {
  flex-basis: 100%;
  position: relative;
}

.inbox__text__msg__wr .inbox__tiny__mce__wr .inbox__tiny__mce__container:after {
  /* position: absolute;
  content: " ";
  height: 100%;
  width: 2px;
  background: #ddd;
  right: 0;
  top: 0; */
}

.inbox__text__msg__wr .inbox__tiny__mce__upload__images {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(65px, 1fr));
  position: relative;
  padding: 0 10px 0 16px;
  max-height: 100px;
  overflow-y: auto;
}

.inbox__text__msg__wr .single__inbox__tiny__image .close {
  color: red;
  font-size: 12px;
  background: white;
  right: 0;
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
}

.inbox__text__msg__wr .single__inbox__tiny__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.inbox__upload__scroll__bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 10px rgba(60, 126, 243, 0.1);
  background-color: rgba(60, 126, 243, 0.1);
  border-radius: 10px;
}

.inbox__upload__scroll__bar {
  /* Thumb */
  scrollbar-face-color: #133159;
  scrollbar-shadow-color: #133159;
  scrollbar-highlight-color: #133159;
  scrollbar-3dlight-color: #133159;
  scrollbar-darkshadow-color: #133159;
  /* Track */
  scrollbar-track-color: #ECF3FE;
  /* Buttons */
  scrollbar-arrow-color: #133159;
}

.inbox__upload__scroll__bar::-webkit-scrollbar-thumb {
  background-color: #133159;
  width: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

.inbox__upload__scroll__bar::-webkit-scrollbar {
  background-color: #ECF3FE;
  width: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

.inbox__upload__scroll__bar {
  scrollbar-color: #133159 #ECF3FE;
  scrollbar-width: thin;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

.inbox__upload__scroll__bar::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(60, 126, 243, 0.1);
  border-radius: 10px;
}

.inbox__upload__scroll__bar::-webkit-scrollbar-thumb {
  background-color: #133159;
  border-radius: 10px;
}

.inbox__voice__msg__wr .inbox__voice__btn__group {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  margin: 0 15px 7px 16px;
}

.inbox__voice__msg__wr .inbox__voice__btn__group_single {
  display: flex;
  align-items: center;
  /* border: 1px solid rgba(19, 49, 89, 0.35);
  padding: 5px 8px;
  border-radius: 6px; */
  grid-gap: 5px;
}

.inbox__voice__msg__wr .reply__alt__voice__btn {
  border: 1px solid rgba(19, 49, 89, 0.35);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
}

.inbox__voice__msg__wr .reply__alt__voice__btn span {
  display: inline-flex;
  font-size: 12px;
  color: var(--dark_blue);
}

.inbox__voice__msg__wr .inbox__voice__personilized .voice_number_select {
  padding: 0 8px !important;
  border-color: rgba(19, 49, 89, 0.35) !important;
  margin: 0 !important;
}




/* chat  css */
.inbox__chat__msg__wr .inbox__chat__msg__area__wr {
  padding: 16px;
  border: 1px solid rgb(229 229 229);
  margin: 16px 0;
  border-radius: 6px;
}

.inbox__chat__msg__wr .inbox__chat__btn__group {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

.inbox__chat__msg__wr .inbox__chat__btn__group_single {
  display: flex;
  align-items: center;
  /* border: 1px solid rgba(19, 49, 89, 0.35);
  padding: 5px 8px;
  border-radius: 6px; */
  grid-gap: 5px;

}

.inbox__chat__msg__wr .inbox__chat__btn__group_single span {
  display: inline-flex;
  font-size: 12px;
}

.inbox__chat__msg__wr .inbox__chat__tiny__mce__wr {
  display: flex;
  margin: 10px 0 0 0;
}

.inbox__chat__msg__wr .inbox__chat__tiny__mce__wr .inbox__chat__tiny__mce__container {
  flex-basis: 100%;
  position: relative;
}

.inbox__chat__msg__wr .inbox__chat__tiny__text__area {
  margin: 8px 0 0;
  padding-top: 0px !important;
  border-color: rgba(0, 0, 0, 0.0) !important;
  color: rgb(71 39 55);
  display: block;
  font-size: 12px;
  box-shadow: unset !important;
  height: 100px;
  resize: none;
}

.inbox__chat__msg__wr .inbox__chat__tiny__text__area:focus {
  outline: none;
}

.inbox__chat__msg__wr .reply__alt__chat__btn {
  border: 1px solid rgba(19, 49, 89, 0.35);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
}

.inbox__chat__msg__wr .reply__alt__chat__btn span {
  display: inline-flex;
  font-size: 12px;
  color: var(--dark_blue);
}

.inbox__chat__msg__wr .inbox__chat__personilized {
  border: 1px solid rgba(19, 49, 89, 0.35) !important;
  border-radius: 5px !important;
  height: 30px !important;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box !important;
  padding: 5px 8px
}

.inbox__chat__msg__wr .inbox__chat__personilized .MuiSelect-select.MuiSelect-select {
  font-size: 12px !important;
  color: var(--dark_blue) !important;
}


.inbox__text__msg__wr .inbox__text__personilized {
  border: 1px solid rgba(19, 49, 89, 0.35) !important;
  border-radius: 5px !important;
  height: 30px !important;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box !important;
  padding: 5px 8px
}

.inbox__text__msg__wr .inbox__text__personilized .MuiSelect-select.MuiSelect-select {
  font-size: 12px !important;
  color: var(--dark_blue) !important;
}

/* Direct Mail */
.inbox__direct__mail__wr .card-listing {
  flex-wrap: wrap;
}

/* Note */
.inbox__note__msg__wr .inbox__note__tiny__text__area {
  margin: 8px 0 0;
  padding: 13px 16px !important;
  border: 1px solid rgb(229 229 229);
  color: rgb(71 39 55);
  display: block;
  font-size: 12px;
  box-shadow: unset !important;
  height: 197px;
  border-radius: 6px;
  resize: none;
}

.inbox__note__msg__wr .inbox__note__tiny__text__area:focus {
  outline: none;
}




@media(max-width: 1200px) {
  .inbox__text__msg__wr .inbox__all__btn__group {
    margin: 0 0 21px 0;
  }

  .inbox__text__msg__wr .inbox__all__btn__group_single span.inbox__max__file__text {
    right: 12px;
    bottom: -16px;
  }
}

.inbox__tiny__mce__container.multimedia::after {
  position: absolute;
  content: " ";
  height: 100%;
  width: 2px;
  background: #ddd;
  right: 0;
  top: 0;
}

.alt____time_out_type {
  margin-right: 0px !important;
}

.user-short-name .responsive-img__alt__inbox {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #ebf2fe;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.replay_text_warp.replay__text__alt__wrap {
  width: auto;
  display: flex !important;
  flex-direction: row !important;
  float: right;
  align-items: flex-end;
  grid-gap: 15px;
}

.replay__text__alt__wrap__container .user-short-name span {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #133159;
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 50px;
}

.replay_text_warp.replay__text__alt__wrap .activation-time.activation-time___alt {
  text-align: right;
}


/* new design chat */
.each_conversation_appointment_container p {
  font-size: 14px !important;
  line-height: 28px !important;
}

.each_conversation_appointment_container .each_conversation_appointment_title {
  background: var(--dark_blue);
  color: #fff !important;
  text-align: center;
}

.each_conversation_appointment_container .each_conversation_appointment_title p {
  color: #fff !important;
  padding: 2px;
}

.each_conversation_appointment_container .each_conversation_appointment_date p:nth-child(3) {
  border-bottom: 1px solid #ddd;
  margin: 0 0 3px 0 !important;
}

.each_conversation_appointment_container .each_conversation_appointment_date .each_conversation_appointment_date__name {
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: #546376;
  font-weight: 600 !important;
}

.each_conversation_appointment_container .each_conversation_appointment_contact span {
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: #546376;
}

.each_conversation_appointment_container .earch__conversation__appointment__name {
  font-weight: 600 !important;
}

.each_conversation_appointment_container .each_conversation_appointment_contact span.earch__conversation__appointment__name {
  font-weight: 600 !important;
}

.each_conversation_appointment_with p strong {
  text-transform: uppercase;
  font-weight: 700;
  word-break: break-word;
}

.not_allowed_send_option {
  cursor: not-allowed;
  opacity: 0.3;
}

/* for conversation skeleton */
.skeleton_each_conversation {
  display: flex;
  grid-gap: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  align-items: flex-end;
}

.skeleton_each_conversation.skeleton_left {
  padding-left: 40px;
}

.skeleton_each_conversation.skeleton_right {
  flex-direction: row-reverse;
  padding-right: 40px;
}

.skeleton_each_conversation.skeleton_right .skeleton_conversation_details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


/* inbox css for signeture design */

.InboxModule-container-parent-class .user_signature_content table h1,
.InboxModule-container-parent-class .user_signature_content table h2,
.InboxModule-container-parent-class .user_signature_content table h3,
.InboxModule-container-parent-class .user_signature_content h4,
.InboxModule-container-parent-class .user_signature_content table h5,
.InboxModule-container-parent-class .user_signature_content table h6,
.InboxModule-container-parent-class .chat__text-wrap .gmail_quote table h1,
.InboxModule-container-parent-class .chat__text-wrap .gmail_quote table h2,
.InboxModule-container-parent-class .chat__text-wrap .gmail_quote table h3,
.InboxModule-container-parent-class .chat__text-wrap .gmail_quote table h4,
.InboxModule-container-parent-class .chat__text-wrap .gmail_quote table h5,
.InboxModule-container-parent-class .chat__text-wrap .gmail_quote table h6 {
  font-size: 16px;
  font-weight: 500;
  margin: 0 !important;
  line-height: 24px;
  word-break: break-word;
}

.InboxModule-container-parent-class .user_signature_content table p,
.InboxModule-container-parent-class .chat__text-wrap .gmail_quote table p {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin: 0 !important;
  line-height: 28px !important;
  color: #80868c !important;
  word-break: break-word;

}

.InboxModule-container-parent-class .user_signature_content table img,
.InboxModule-container-parent-class .chat__text-wrap .gmail_quote table img {
  width: 110px;
  object-fit: contain;
}


.InboxModule-container-parent-class .inbox_toggle_btn {
  transition: all 1s ease 0s;
}


.InboxModule-container-parent-class .mark__all__as__read {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.InboxModule-container-parent-class .mark__all__as__read button {
  background: var(--gray-6);
  border-radius: 5px;
  box-shadow: unset;
  color: var(--dark_blue);
  padding: 7px 20px;
  height: 40px;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-transform: initial;
  border: 0;
  margin-right: 23px;
  cursor: pointer;


}

.InboxModule-container-parent-class .ismss_footer__wr {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.InboxModule-container-parent-class .ismss_footer__wr .add__unsubscried__text {
  display: flex;
  align-items: center;
  grid-gap: 3px;
}

.InboxModule-container-parent-class .inbox__text__global__personalized {
  font-size: 12px !important;
  color: var(--dark_blue) !important;
}

.InboxModule-container-parent-class .inbox__text__global__personalized .label_class_wrapper {
  padding: 5px 8px;
  border-color: rgba(19, 49, 89, 0.35);
}

.InboxModule-container-parent-class .inbox__chat__global__personalized {
  font-size: 12px !important;
  color: var(--dark_blue) !important;
}

.InboxModule-container-parent-class .inbox__chat__global__personalized .label_class_wrapper {
  padding: 5px 8px;
  border-color: rgba(19, 49, 89, 0.35);
}

.InboxModule-container-parent-class .chat__text-wrap .chat-text .flow-text {
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #133159;
  word-break: break-word;
}

.InboxModule-container-parent-class .chat__text-wrap .chat-text .flow-text {
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #133159;
  word-break: break-word;
}

.InboxModule-container-parent-class .chat__text-wrap .chat-text .flow-text {
  margin: 0 !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #133159 !important;
  word-break: break-word;
}


/* global scroll bar for pdad */
.tdp_scroll_bar {
  height: 100%;
  padding-bottom: 4px;
  overflow-y: auto;
}

.tdp_scroll_bar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.tdp_scroll_bar::-webkit-scrollbar-track {
  background: rgba(60, 126, 243, 0.1);
  border-radius: 4px;
}

.tdp_scroll_bar::-webkit-scrollbar {
  scrollbar-width: thin;
}

.tdp_scroll_bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #949DB2;
  box-sizing: border-box;
}

.tdp_scroll_bar::-webkit-scrollbar-track {
  background: #F4F5F7;
  box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
  -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}

.contact_timeline_email_body img {
  max-width: 100%;
}

.dncStatusChip {
  background-color: #F30000;
  color: #fff;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 10px;
}

.InboxModule-container-parent-class  .inbox-chat-text-rm-padding{
  padding: 0px !important;
}
.InboxModule-container-parent-class .inbox-chat-message{
  padding-left: 10px;
}

.InboxModule-container-parent-class .inbox-chat-text-email{
  padding-left:10px;
  padding-top: 8px !important;
}

.InboxModule-container-parent-class .smart-form-response {
  padding: 12px 15px;
}

.InboxModule-container-parent-class .smart-form-subject {
  border-radius: 10px 10px 0 0;
}

.InboxModule-container-parent-class .chat__reply {
  cursor: pointer;
  float: right;
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #3C7EF3;
  display: flex;
  padding: 5px 11px 5px 5px;
  border-radius: 4px;
  font-size: 12px;
  gap: 6px;
  color: #fff;
}

.InboxModule-container-parent-class .chat__reply svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  user-select: none;
}

.InboxModule-container-parent-class .chat__reply svg:active {
  box-shadow: 0 1px gray;
  transform: translateY(2px);
}

.InboxModule-container-parent-class .incoming_chat_relative {
  position: relative;
}

blockquote {
  margin: 0;
  padding-left: 0;
  border-left: 0;
}

@media (max-width: 1600px) {
  .InboxModule-container-parent-class .chat__text-wrap {
    padding-left: 30px !important;
  }
}