.buttonStyle {
  background-color: #00ff91 !important;
  height: 20px !important;
  display: flex !important;
  font-size: 10px !important;
  padding: 0 15px !important;
  color: #0e0f3f !important;
  font-weight: 400 !important;
  line-height: 0px !important;
  border-radius: 40px !important;
  margin-left: auto;
}
.buttonStyle:hover {
  background-color: #00ff91 !important;
}
.validButton {
  width: 77px !important;
}

.emailBoxWidth {
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.emailIconButton {
  padding: 3px !important;
}
@media only screen and (min-width: 1111px) and (max-width: 1299px) {
  .emailBoxWidth {
    max-width: 122px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 1300px) {
  .emailBoxWidth {
    max-width: 167px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1920px) {
  .emailBoxWidth {
    max-width: 270px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (min-width: 1920px) {
  .emailBoxWidth {
    max-width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 1650px) {
  .buttonStyle {
    background-color: #00ff91;
    height: 19px;
    width: 70px;
    display: flex;
    font-size: 10px;
    border-radius: 30px;
  }
  .buttonStyle:hover {
    background-color: #00ff91;
  }
}
