.second__step__area__select {
    border: 1px solid rgba(60, 126, 243, 0.1) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    position: relative !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500;
    padding: 10px 8px 14px!important;
    height: 42px !important;
    box-sizing: border-box;
}
.second__step__area__select.MuiInput-underline:before{
    border-bottom: 0px solid rgba(0,0,0,0.0) !important;
}
.second__step__area__select .MuiSelect-selectMenu {
    overflow: visible; 
    min-height: auto;
    white-space: wrap;
    text-overflow: initial;
    min-width: 100% !important;
    display: flex;
    
  }
  .second__step__area__one
  .second__step__area__label{
      padding-bottom: 20px;
  }
.second__step__area__select .MuiSelect-select {
    width: 100% !important;
    height: 100% !important;
  }
  .second__step__area__select .MuiSelect-select:focus {
    border-radius: 0;
    background-color: rgba(0, 0, 0,0.0) !important;
    width: 100% !important;
    height: 100% !important;
  }
  .second__step__area__select.assign__stage_select_wrapper .MuiSelect-select:focus {
      height: 45px !important;
  }
  .second__step__area__select .MuiSelect-select,
.MuiPaper-rounded ul,
.custom-input .MuiInputBase-root{
    font-size: 14px !important;
    line-height: 22px!important;
    /* line-height: 9px!important; */
    letter-spacing: 0.01em !important;
    color: #8896A8 !important;
    font-family: var(--Poppins) !important;
    font-style: normal !important;
    font-weight: normal !important;
}
.MuiMenuItem-root{
    font-family: var(--Poppins) !important;
}
.second__step__area__select__100{
    width: 100%;
}

.second__step__area__select__100 .MuiInput-underline:before{
    border-bottom: 0 !important;
}
.second__step__area__select__100 .MuiInput-underline::after{
    border-bottom: unset !important;
}
.droupdown__helper .MuiFormControl-root.second__step__area__select.second__step__area__select__100 {
    margin: 0 !important;
    padding: 0 !important;
    height: 100% !important;
    display: flex;
    height: 45px !important;
}

.all-select-area .droupdown__helper .MuiSelect-select {
    align-items: center !important;
}

.MuiSelect-select.MuiSelect-select {
    box-sizing: border-box;
}
.ppc__lead_source .second__step__area__select .MuiSelect-select.MuiSelect-select {
    padding-left: 60px;
    box-sizing: border-box;
} 
.filter-onwer .droupdown__helper .MuiFormControl-root,
.filter-onwer .select-wrapper input{
    background: #FFFFFF;
    border-radius: 5px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #546376;
    padding-left: 10px;
    box-sizing: border-box;
}
.droupdown__helper.ppc__select_owner + .droupdown__helper.ppc__select_owner {
    margin-top: 15px;
}
.assign-stage-single-item .select-wrapper input{
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: unset;
    border-bottom: unset;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #133159;
    box-sizing: border-box;
    padding-left: 17px;
}
.assign-stage-single-item .select-wrapper input:focus{
    outline: none;
    border-bottom: unset;
}
.assign-stage-single-item .select-wrapper svg{
    display: none;
}
.assign-stage-single-item .select-wrapper ul.dropdown-content{
    top: 44px !important;
}
.assign-stage-single-item .select-wrapper ul li span{
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #133159;
}
.pagenation-top .select-wrapper input:focus{
    border-bottom: unset !important;
}
.pagenation-top .select-wrapper  ul li span{
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #133159;
}
.custom-select-arrow .select-wrapper svg{
    display: none;
}
.select-wrapper {
    width: 100%;
    height: auto;
    display: block;
}